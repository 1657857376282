import { LOCAL_UI_QUERY, LOCAL_MESSAGING_QUERY } from '../graphql/queries';
import toggleBodyScroll from '../../utils/toggle-body-scroll';

const uiPopupStateItems = [
  "signupOverlayActive",
  "chatOverlayActive",
  "mobileMenuOpen",
  "premiumCofounderOverlayActive",
  "foundersGridAdditionalDetailsId",
  "founderFiltersActive",
  "profilePicUploadActive",
  "promoCodeAppTileId"
];

const Mutation = {
  toggleOverlay(_, variables, { cache }) {
    const { ui } = cache.readQuery({
      query: LOCAL_UI_QUERY
    });
    
    const data = {
      data: {
        ui: {
          ...ui,
          signupOverlayActive: !ui.signupOverlayActive,
          mobileMenuOpen: false
        }
      }
    };

    cache.writeData(data);
    return data;
  },
  toggleAppPromoCode(_, variables, { cache }) {
    const { ui } = cache.readQuery({
      query: LOCAL_UI_QUERY
    });

    const data = {
      data: {
        ui: {
          ...ui,
          promoCodeAppTileId: variables.appId ? variables.appId : null
        }
      }
    };

    cache.writeData(data);
    return data;
  },  
  toggleFoundersGridAdditionalDetails(_, variables, { cache }) {
    const { ui } = cache.readQuery({
      query: LOCAL_UI_QUERY
    });

    const data = {
      data: {
        ui: {
          ...ui,
          foundersGridAdditionalDetailsId: variables.foundersGridAdditionalDetailsId ? variables.foundersGridAdditionalDetailsId : ''
        }
      }
    };

    cache.writeData(data);
    return data;
  },  
  toggleFounderFilters(_, variables, { cache }) {
    const { ui } = cache.readQuery({
      query: LOCAL_UI_QUERY
    });

    const data = {
      data: {
        ui: {
          ...ui,
          founderFiltersActive: !ui.founderFiltersActive
        }
      }
    };

    cache.writeData(data);
    return data;
  },  
  toggleUploadProfilePic(_, variables, { cache }) {
    const { ui } = cache.readQuery({
      query: LOCAL_UI_QUERY
    });

    const data = {
      data: {
        ui: {
          ...ui,
          profilePicUploadActive: !ui.profilePicUploadActive
        }
      }
    };

    cache.writeData(data);
    return data;
  },  
  togglePremiumCofounder(_, variables, { cache }) {
    const { ui } = cache.readQuery({
      query: LOCAL_UI_QUERY
    });

    const data = {
      data: {
        ui: {
          ...ui,
          premiumCofounderOverlayActive: !ui.premiumCofounderOverlayActive,
          premiumCofounderOverlayMessage: variables.message
        }
      }
    }

    cache.writeData(data);
    return data;
  },
  toggleMessages(_, variables, { cache }) {
    const { messaging } = cache.readQuery({
      query: LOCAL_MESSAGING_QUERY
    });

    const { ui } = cache.readQuery({
      query: LOCAL_UI_QUERY
    });    

    const data = {
      data: {
        ui: {
          ...ui,
          mobileMenuOpen: false
        }
      }
    }
    if (variables.convoOtherUserId) {
      data.data.messaging = {
        ...messaging,
        convoOtherUserId: variables.convoOtherUserId,
        convoOtherUserProfilePic: variables.convoOtherUserProfilePic,
        convoOtherUserFirstName: variables.convoOtherUserFirstName,
        activeConvoId: variables.activeConvoId
      }
      data.data.ui.chatOverlayActive = true;
      toggleBodyScroll('', '', 'ON');
    } else {
      data.data.ui.chatOverlayActive = !ui.chatOverlayActive;
      toggleBodyScroll(ui, 'chatOverlayActive');
    }

    cache.writeData(data);
    return data;
  },
  toggleMobileMenu(_, variables, { cache }) {
    const { ui } = cache.readQuery({
      query: LOCAL_UI_QUERY
    });   

    let mobileMenuOpen = !ui.mobileMenuOpen;
    if (typeof variables.value !== 'undefined') mobileMenuOpen = variables.value;

    toggleBodyScroll(ui, 'mobileMenuOpen');

    const data = {
      data: {
        ui: {
          ...ui,
          mobileMenuOpen,
          chatOverlayActive: false
        }
      }
    }

    cache.writeData(data);
    return data;
  }
}

export { uiPopupStateItems };
export default Mutation;
