import { uiPopupStateItems } from '../apollo-state/resolvers/mutation-resolvers';

function toggleBodyScroll(UI, UIItem, STATE) {
  if (process.browser) {
    if (STATE === 'OFF') {
      return toggleOff();
    }

    if (STATE === 'ON') {
      return toggleOn();
    }    

    const isCurrentlyOn = [...document
      .getElementsByTagName('body')[0]
      .classList]
      .filter(classname => classname === 'no-scroll')
      .length > 0;

    if (!isCurrentlyOn) {
      toggleOn();
    }

    if (isCurrentlyOn && !anotherOverlayIsOpen(UI, UIItem)) {
      toggleOff();
    }
  }
}

function toggleOn() {
  document
    .getElementsByTagName('body')[0]
    .classList
    .add('no-scroll');
}

function toggleOff() {
  document
    .getElementsByTagName('body')[0]
    .classList
    .remove('no-scroll');
}

function anotherOverlayIsOpen(ui, UIItem) {
  const array = uiPopupStateItems
    .filter(item => (item !== UIItem) && (ui[item]));
    return array.length > 0;
}

export default toggleBodyScroll;