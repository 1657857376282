import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './form.scss';

class OverlayForm extends PureComponent {
  onSubmit = e => {
    e.preventDefault();
    this.props.onSubmit();
  };
  render() {
    const { children, loading, method, className, autoCompleteDisabled } = this.props;

    return (
      <fieldset disabled={loading} aria-busy={loading}>
        <div className={classNames('form' , 
          {
            className
          }
        )}>
          <div className="form__inner">
            <div className={classNames('form__loading', {
              'form__loading--active': loading
              })}
            />
            <form method="post" onSubmit={this.onSubmit} autoComplete={autoCompleteDisabled ? 'off' : 'true'}>
              {children}
            </form>
          </div>
        </div>
      </fieldset>
    );
  }
};

export default OverlayForm;
