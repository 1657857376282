import gql from 'graphql-tag';

const LOCAL_UI_QUERY = gql`
  query {
    ui @client {
      signupOverlayActive,
      chatOverlayActive,
      mobileMenuOpen,
      premiumCofounderOverlayActive,
      premiumCofounderOverlayMessage,
      foundersGridAdditionalDetailsId,
      founderFiltersActive,
      profilePicUploadActive,
      promoCodeAppTileId
    }
  }
`;

const LOCAL_MESSAGING_QUERY = gql`
  query {
    messaging @client {
      convoOtherUserId
      convoOtherUserProfilePic
      convoOtherUserFirstName
      activeConvoId
    }
  }
`;

export {
  LOCAL_UI_QUERY,
  LOCAL_MESSAGING_QUERY
}
