import React from 'react';

import { object } from 'prop-types';

const DisplayError = ({ error }) => {
  if (!error || !error.message) return null;
  if (error.networkError && error.networkError.result && error.networkError.result.errors.length) {
    return error.networkError.result.errors.map((error, i) => (
      <div key={i}>
        <p data-test="graphql-error">
          <strong>
            {error.message.replace('GraphQL error: ', '')}
          </strong>
        </p>
      </div>
    ));
  }
  return (
    <div>
      <p data-test="graphql-error">
        <strong>
          {error.message.replace('GraphQL error: ', '')}
        </strong>
      </p>
    </div>
  );
};

DisplayError.defaultProps = {
  error: {}
};

DisplayError.propTypes = {
  error: object
};

export default DisplayError;