import React, { PureComponent } from 'react';
import gql from 'graphql-tag';
import { Mutation, ApolloConsumer } from 'react-apollo';

import Form from '../form/form';
import TextInput from '../text-input/text-input';
import Error from '../error-message/error-message';
import Cta from '../cta/cta';

import { event } from '../../lib/gtag';

const SIGNIN_MUTATION = gql`
  mutation SIGNIN_MUTATION(
    $email: String!,
    $password: String!) {
      signIn(
        email: $email,
        password: $password
      ) {
        id
        firstName
        lastName
        email
        token
      }
    }
`;

class SigninForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      signInEmail: '',
      signInPassword: ''
    };
  }

  updateValue = ({ forLabel, value }) => {
    this.setState({ [forLabel]: value });
  };

  setCookie = (resp, client) => {
    if (resp.signIn.token) {
      const token = resp.signIn.token;
      localStorage.setItem('token', token);
      fetch(`/authcallback?token=${token}`).then(() => {
        client.resetStore();
      })
    }
  };

  signInPre = signIn => {
    if (this.state.signInEmail.length === 0 || this.state.signInPassword.length === 0) {
      const realEmailValue = document.getElementById('signInEmail').value;
      const realPasswordValue = document.getElementById('signInPassword').value;
      this.setState({
        signInEmail: realEmailValue,
        signInPassword: realPasswordValue
      }, () => signIn())
    } else {
      signIn();
    }
  };
  
  render() {
    const { signInEmail, signInPassword } = this.state;
    const { toggleSignOrPass } = this.props;

    return (
      <ApolloConsumer>
        {client => (
          <Mutation
            mutation={SIGNIN_MUTATION}
            variables={{
              email: signInEmail,
              password: signInPassword
            }}
            awaitRefetchQueries
            onCompleted={resp => {
              this.setCookie(resp, client);
              event({
                action: 'login'
              });
            }}
          >
            {(signIn, { error, loading }) => {
              return (
                <Form onSubmit={() => this.signInPre(signIn)} method="post" loading={loading} forceUpdateState autoCompleteDisabled>
                  <Error error={error} />
                  <br />
                  {loading && <span />}
                  <TextInput
                    label="Email"
                    forLabel="signInEmail"
                    type="text"
                    updateValue={this.updateValue}
                    value={signInEmail}
                    isValid={true}
                  />
                  <TextInput
                    label="Password"
                    forLabel="signInPassword"
                    type="password"
                    updateValue={this.updateValue}
                    value={signInPassword}
                    isValid={true}
                  />
                  <Cta tag="button" type="submit" text="Sign in" />
                  <br />
                  <Cta type="button" modifierClass="forgot-pass" tag="button" text="I forgot my password" onClick={toggleSignOrPass} basic />
                </Form>
              )
            }}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }
};

export default SigninForm;